import { HttpHeaders } from '@angular/common/http';

export const contentHeaders = new HttpHeaders();
contentHeaders.append('Accept', 'application/json');
contentHeaders.append('Content-Type', 'application/json');

export class ApiResponse<T> {
  public data: T | undefined;
  public error: any;
  public success: boolean | undefined;
  public isFatal: boolean | undefined;
  public pageSize: any;
  public pageNumber: any;
  public totalCount: any;
}

export class ApiPagedResponse<T> extends ApiResponse<T> {
  public count: number | undefined;
}

export class Configuration {
  public apiBaseUrl: string | undefined;
  public clientBaseUrl: string | undefined;
  public msalConfig: any;
  public stripe: any;
  public applicationInsights: any;
  public decisionEnablement: any;
}

export interface SchedulerReportConfiguration {
  key: string;
  value: string;
}

export enum DaysOfWeek {
  SUN = 0,
  MON = 1,
  TUES = 2,
  WED = 3,
  THURS = 4,
  FRI = 5,
  SAT = 6
}
