<div>
  <div class="title-bar">
    <button type="button" aria-label="Toggle sidenav" mat-icon-button class="toggle_icon_mobile" (click)="drawer.toggle();">
      <img *ngIf="!isNavSideOpen" class="toggle_icon" src="./app/assets/icons/toggle_icon.svg" />
      <img *ngIf="isNavSideOpen" class="toggle_icon" src="./app/assets/icons/close.svg" />
    </button>

    <div class="title-bar__brand">
      <img class="title-bar__brand__logo" src="./app/assets/icons/wordmark.svg" />
    </div>
    <div class="title-bar__nav-menu" *ngIf="navigationList && (role != roles.participant || (role == roles.participant && showParticipantMenu))">
      <a class="title-bar__nav-menu__item text--small" (click)="navigate(item.url)" *ngFor="let item of navigationList" [ngClass]="{'disabled': !item.url, 'active' : item.isActive}" [routerLink]="[item.url]">
        {{item.title}}
      </a>
    </div>
    <div class="title-bar__account d-flex align-center" *ngIf="userInformation">
      <a aria-label="Account Menu" alt="Account Menu" [matMenuTriggerFor]="AccountMenu" class="button circular cursor-pointer">
        {{userInformation.initials}}
      </a>
      <mat-menu #AccountMenu="matMenu" [overlapTrigger]="false" class="title-bar__account__menu">
        <a aria-label="User Initials" alt="User Initials" class="button circular pb-24">
          {{userInformation.initials}}
        </a>
        <div class="text mb-16">{{userInformation.fullName}}</div>
        <div class="text mb-24">{{userInformation.username}}</div>

        <div (click)="$event.stopPropagation();" *ngIf="role == roles.clientadmin || role ==  roles.clientmaster">
          <mat-form-field class="mat-form-field w-100" hideRequiredMarker appearance="outline">
            <mat-select name="Company" placeholder="Select Company" [(ngModel)]="activeB2BAccountId" #Company="ngModel">
                <mat-option *ngFor="let client of b2bAccountIds" [value]="client.clientId" (click)="onClientChange();">
                  {{client.clientName}}
                </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <hr class="mercury_color mb-24" />
        <button mat-menu-item (click)="accountSettings()" class="text text--bold royal_color" *ngIf="role == roles.participant">
          <img src="./app/assets/icons/account-setting.svg" />
          <span>Account Settings</span>
        </button>
        <button mat-menu-item (click)="changeEmail()" class="text text--bold royal_color" *ngIf="role!= roles.participant">
          <img src="./app/assets/icons/change-email.svg" />
          <span>Change Email</span>
        </button>
        <button mat-menu-item (click)="logOut()" class="text text--bold royal_color">
          <img src="./app/assets/icons/account-logout.svg" />
          <span>Log Out</span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>

<mat-drawer-container class="mat-toggle-bar" [hasBackdrop]="true">
  <mat-drawer #drawer mode="over" (openedChange)="openSideNavBar($event)">
    <div class="side-nav-bar white_bgcolor" *ngIf="userInformation.fullName">
      <div class="side-nav-bar-inner">
        <img class="mb-32" src="./app/assets/icons/Thrivepass_logo_heart.svg" />
        <h3 class="ebony_color mb-8">{{userInformation.fullName}}</h3>
        <p class="cmp-name mb-36 ebony_color">{{userInformation.username}}</p>

        <div class="menu-container" *ngIf="(role != roles.participant) || (role == roles.participant && showParticipantMenu)">
          <a *ngFor="let item of navigationList" class="menu-item cursor-pointer" [ngClass]="{'disabled': !item.url, 'selected-menu' : item.isActive}" [routerLink]="[item.url]" (click)="navigate(item.url)">
            <img src="{{item.icon}}" /><span class="menu-name">{{item.title}}</span>
          </a>
        </div>
      </div>
    </div>
  </mat-drawer>
</mat-drawer-container>
